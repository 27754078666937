// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { env } from "ng-viv-ux";

export const environment: env = {
  production: true,
  sdk: import('ng-vex-sdk'),
  dark_logo: 'magnum_logo.png',
  light_logo: 'magnum_logo.png',
  title: 'Magnum',
  version: require('../../package.json').version,
  apiAccessPoint: {
    protocol: 'https',
    hostname: 'srv.magnum-d.bg',
    path: 'api'
  },
  // This shoud be removed
  showLabelKeyIfNotFound: true,
  // tslint:disable-next-line: max-line-length
  apiToken: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiYTZhNjI1ZTRjY2Q0N2U3MmM3NTA2YzNiZWNmYzdkNmVmYzc2MDUyZGQzNTkzNTA5MjU0OTQ3MThhOTNlZDc0NjJjZGQwZGY3YjcwMTM0M2IiLCJpYXQiOjE3MzU2NDY4MTEuMzYxNTQ1LCJuYmYiOjE3MzU2NDY4MTEuMzYxNTU4LCJleHAiOjE3NjcxODI4MTAuNDg1MjM3LCJzdWIiOiIiLCJzY29wZXMiOltdfQ.B8yYRFqT8g_dVDZBXrZdg2c8HWm_OGZszVk0kIGoKMJ1020VOFvgurWwKRPWmX-jBudgml8Dyk1Kd2TUj1wpRS4CvsfiAtCQMq-q0rEPuHa_PJVaLRM6pOFqRxWE14eh451cuTb2-mndItK504c-EE9x6X21FrxnX4OKwG9PEB38604DnF3eurG1fwxFUZ7N2KfHie2Gh_Z8ahd9Oxj5O4U_MEIEyN-QMEW0w0BXviOSz9lmQ5zfc3VSafdhXGKnSeKlc65z8ikI8-UXsXlrFPmVG7GErtEwTU6lu2mGz8AXt0xOMJ1pr3ujzRR6DYv-Y0H3azoCqvl0bcUiMm9RP33AfDMOTJZE4Jyg-JlSl97OiFCbjt-eEPD908RQ26Nhzs0hsCYM-dOQoTrqnDsprHahM8NVOijVhidz7YCgHVTqb3W9BCs43i9qwLlGOu103sJNfXo6dl0fci-5778AimOP_o6rvyE3q7AQnte5qxlx-vSnIgoh01O_EpdSaowvDDT9YKPmaavjdRV80vJTQJ7f_8Npvvh9r7G5e6VG5W7752byjP8gf-twbHm4zBEuO8xBAzHcjC2l-dZjQBDdaHiSk0xPU8bVJQaPuK6nUpZjJweeyfxfkau2A7ju9Yn1V3gDdGvOx-epp7nemfPSO7x9vpIm_WHNSbCGr69w8n0',
  mapApiToken: 'AIzaSyCfecV8GfLbgx6Ciw3uDhcPV3S-UtarrvQ',
  recaptchaApiToken: '6LcEjiEgAAAAADk_cANagYtJqPG7Cx3ZkrwilUfO',
  noLoaderRequests: [
    {
      url: '/api',
      body: {
        method: 'app.locale.getLocales'
      }
    },
    {
      url: '/api',
      body: {
        method: 'access.user.checkSession'
      }
    },
    {
      url: '/api',
      body: {
        method: 'app.label.getPublished'
      }
    },
    {
      url: '/api',
      body: {
        method: 'menu.menu.view'
      }
    },
    {
      url: '/api',
      body: {
        method: 'banner.banner.view'
      }
    },
    {
      url: '/api',
      body: {
        method: 'product.product.list'
      }
    },
    {
      url: '/api',
      body: {
        method: 'order.cart.loadCart'
      }
    },
    {
      url: '/api',
      body: {
        method: 'product.product.view'
      }
    },
    {
      url: '/api',
      body: {
        method: 'product.product.lastViewedProducts'
      }
    },
    {
      url: './assets/routing-mock.json?v=1.0.0'
    }
  ]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
